<template>
  <div id="app">
    <TemplateAppVue/>
  </div>
</template>

<script>
import TemplateAppVue from './template/template-app.vue';

export default {
  name: 'App',
  components: {TemplateAppVue}
}
</script>

<style>
</style>
