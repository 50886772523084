<template>
   <button class="btn-neon" @click="handleClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
methods: {
    handleClick() {
      this.$emit('click');
    },
  },
}
</script>

<style>
.btn-neon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.0rem 2rem;
  width: 190px;
  height: 40px;
  background: transparent;
  border-radius: 0.6rem;
  font-size: 18px;
  color: var(--main-color);
  letter-spacing: 0.1rem;
  font-weight: 600;
  border: 0.5px solid var(--main-color);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.btn-neon:hover {
  box-shadow: 0 0 10px var(--main-color), 0 0 15px var(--main-color) inset;
  text-shadow: 0 0 15px var(--main-color);
}
</style>