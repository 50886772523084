<template>
  <section id="main-projects">
    <div class="card">
      <h2 v-scroll-reveal.reset>PROJETOS EM DESTAQUE</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <hr v-scroll-reveal.reset="{delay:25}">
      <PrimaryProjectVue v-scroll-reveal.reset="{delay:50}"/>
      <hr v-scroll-reveal.reset="{delay:75}">
      <SecondProjectVue v-scroll-reveal.reset="{delay:100}"/>
      <hr v-scroll-reveal.reset="{delay:125}">
    </div>  

  </section>
</template>

<script>
import PrimaryProjectVue from "./components/primary-project.vue";
import SecondProjectVue from "./components/second-project.vue";
export default {
  components: {
    PrimaryProjectVue,
    SecondProjectVue,
  },
};
</script>

<style scoped>
section {
  margin-top: 50px;
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
}

hr {
  height: 0.5px;
  width: 100%;
  opacity: 0.2;
  background-color: var(--text-color-strong);
}

h2 {
  padding: 20px 0;
  font-size: 2.6em;
  color: var(--text-color-strong);
}

.card {
  padding: 50px 5%;
  margin-top: 40px;
  border-radius: 6px;
  position: relative;
  background: transparent;
  overflow: hidden;
}

.card span:nth-of-type(1){
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, transparent, var(--main-color));
  animation: animationOne 3s linear infinite;
}

@keyframes animationOne {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.card span:nth-of-type(2){
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(to bottom, transparent, var(--main-color));
  animation: animationTwo 3s linear infinite;
   animation-delay: 1.5s;
}

@keyframes animationTwo {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

.card span:nth-of-type(3){
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to left, transparent, var(--main-color));
  animation: animationTree 3s linear infinite;
}

@keyframes animationTree {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
.card span:nth-of-type(4){
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(to top, transparent, var(--main-color));
  animation: animationFour 3s linear infinite;
  animation-delay: 1.5s;
}

@keyframes animationFour {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}
</style>