<template>
  <section id="skills">
    <div class="content">
      <h2 v-scroll-reveal.reset>HABILIDADES</h2>

      <div class="items">
        <article class="item" v-scroll-reveal.reset="{ delay: 100 }">
          <h3>Ferramentas de trabalho</h3>
          <hr />
          <div class="list-software" v-for="item in listSkills" :key="item.id">
            <h4>{{ item.name }}</h4>

            <div class="bar">
              <div class="progressbar" :style="{ width: item.percent }" />
            </div>
          </div>
        </article>

        <article class="item" v-scroll-reveal.reset="{ delay: 300 }">
          <h3>Linguagems e Frameworks</h3>
          <hr />
          <div
            class="list-software"
            v-for="item in listSoftwares"
            :key="item.id"
          >
            <h4>{{ item.name }}</h4>

            <div class="bar">
              <div class="progressbar" :style="{ width: item.percent }" />
            </div>
          </div>
        </article>

        <article class="item" v-scroll-reveal.reset="{ delay: 500 }">
          <h3>Soft Skills</h3>
          <hr />
          <div class="list-software" v-for="item in listKnowHow" :key="item.id">
            <h4>{{ item.name }}</h4>

            <div class="bar">
              <div class="progressbar" :style="{ width: item.percent }" />
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Skills-app",
  data() {
    return {
      percent: 0,
      listSoftwares: [
        { id: 1, name: "Java", percent: "80%" },
        { id: 2, name: "Spring Boot", percent: "80%" },
        { id: 3, name: "Flutter", percent: "70%" },
        { id: 4, name: "Dart", percent: "70%" },
        { id: 5, name: "Vue JS", percent: "75%" },
        { id: 6, name: "Java Script/HTML/CSS", percent: "70%" },
      ],
      listKnowHow: [
        { id: 1, name: "Organização", percent: "90%" },
        { id: 2, name: "Proatividade", percent: "95%" },
        { id: 3, name: "Criatividade", percent: "90%" },
        { id: 4, name: "Resiliência", percent: "90%" },
        { id: 5, name: "Resolução de problemas", percent: "90%" },
      ],
      listSkills: [
        { id: 1, name: "MYSQL", percent: "80%" },
        { id: 2, name: "Docker", percent: "60%" },
        { id: 3, name: "Postman", percent: "95%" },
        { id: 4, name: "RabbitMQ", percent: "70%" },
        { id: 5, name: "Jira", percent: "95%" },
        { id: 6, name: "Github", percent: "95%" },
        { id: 7, name: "Google Cloud", percent: "65%" },
        { id: 8, name: "Azure DevOps", percent: "65%" },
      ],
    };
  },
};
</script>

<style scoped>
#skills {
  padding-top: 60px;
}

.content {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  padding: 50px 0;
  font-size: 2.6em;
  color: var(--text-color-strong);
}

.items {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

h3 {
  padding: 5px 0 5px 0;
  font-size: 2em;
  color: var(--text-color-strong);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h4 {
  padding: 5px 0 5px 0;
  font-size: 1.7em;
  font-weight: 500;
  color: var(--text-color);
}

.item {
  height: auto;
  width: 30%;
}

hr {
  display: block;
  border-style: inset;
  border-width: 0.1px;
  background: var(--text-color);
  opacity: 0.3;
}

.list-software {
  padding: 15px 0;
}

.bar {
  height: 8px;
  background: var(--text-color);
  width: 100%;
  border-radius: 8px;
}

.progressbar {
  height: 100%;
  background: var(--main-color);
  border-radius: 3px;
  animation: pecenting 2s linear;
}

@keyframes pecenting {
  0% {
    width: 0;
  }
}

@media (max-width: 500px) {
  .items {
    flex-direction: column;
  }

  .item {
    width: 100%;
    margin-bottom: 50px;
  }
}
</style>