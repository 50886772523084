<template>
  <div id="template-app">
    <HeaderAppVue />
    <main>
      <router-view></router-view>
    </main>
    <FooterAppVue/>
  </div>
</template>

<script>
import HeaderAppVue from "@/components/header/header-app.vue";
import FooterAppVue from "@/components/footer/footer-app.vue";

export default {
  name: "template-app",
  components: {
    HeaderAppVue,
    FooterAppVue,
  },
};
</script>

<style scoped>
  .bg-footer {
    background: var(--main-color);
  }
</style>