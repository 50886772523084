<template>

 <header id="header-app">
    <div class="navbar-content">
      <a v-scroll-to="'#home'" class="logo"><h2 >André Ramos</h2></a>

      <div class="navbar">
        <a v-scroll-to="'#home'">Home</a>
        <a v-scroll-to="'#about'">Sobre</a>
        <a v-scroll-to="'#skills'">Habilidades</a>
        <a v-scroll-to="'#main-projects'">Projetos</a>
        <a v-scroll-to="'#others-projects'">Outros</a>
        <a v-scroll-to="'#contact'">Contato</a>
      </div>

      <div class="social-media">
        <a href="https://www.facebook.com/andre.felipe.902819/" target="_blank"><i class="bx bxl-facebook"></i></a>
        <a href="https://www.instagram.com/andre.felipe.hunter/" target="_blank"><i class="bx bxl-instagram"></i></a>
        <a href="https://www.linkedin.com/in/andrefsramos-tech/" target="_blank"><i class="bx bxl-linkedin"></i></a>
        <a href="https://github.com/AndreFSRamos" target="_blank"><i class="bx bxl-github"></i></a>
      </div>

      <div class="navbar-mobile" :style="{width : openCloseSideBar}" @click="showMenuMobile = !showMenuMobile">
        <a v-scroll-to="'#home'">Home</a>
        <a v-scroll-to="'#about'">Sobre</a>
        <a v-scroll-to="'#skills'">Habilidades</a>
        <a v-scroll-to="'#main-projects'">Projetos</a>
        <a v-scroll-to="'#others-projects'">Outros</a>
        <a v-scroll-to="'#contact'">Contato</a>
      </div>

      <i :class="changeIcon" @click="showMenuMobile = !showMenuMobile"></i>
    </div>
  </header>
</template>

<script>
export default {
  name: "header-app",
  data() {
    return {
      showMenuMobile: true,
    };
  },
  computed: {
    openCloseSideBar() {
      return !this.showMenuMobile ? "58vw" : "0";
    },
    changeIcon() {
      return this.showMenuMobile
        ? "bx bx-menu-alt-right menu-icon"
        : "bx bx-x menu-icon";
    },
  },
};
</script>

<style scoped>
strong {
  color: var(--main-color);
}

header {
  position: fixed;
  top: 0;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: 0.5s ease;
  background: var(--black-color);
}

.logo {
  font-size: 1.1rem;
  color: var(--main-color);
  font-weight: 300;
  cursor: pointer;
}

.navbar-content {
  margin: auto;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar a {
  position: relative;
  font-size: 1.4rem;
  color: var(--text-color-strong);
  font-weight: 500;
  margin-right: 2.5rem;
  cursor: pointer;
}

.navbar a:hover{
  color: var(--main-color);
  text-shadow: 0 0 15px var(--main-color);
}

.social-media a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border-radius: 4px;
  font-size: 2rem;
  color: var(--text-color-strong);
  margin: 2.5rem 0.5rem 3rem 0;
  border-radius: 50%;
  transition: 0.2s ease;
}

.social-media a:hover {
  color: var(--main-color);
  box-shadow: 0 0 15px var(--main-color), 0 0 15px var(--main-color) inset;
  text-shadow: 0 0 15px var(--main-color);
}



.navbar-mobile {
  display: none;
}

.menu-icon {
  position: relative;
  display: none;
  color: var(--text-color-strong);
  font-size: 4rem;
}

.bx {
  transition: 0.3s ease;
}

.destaque {
  width: 0;
}

@media (max-width: 980px) {
  .navbar {
    display: none;
  }

  .social-media{
    display: none;
  }

  .navbar-mobile {
    padding-top: 80px;
    background: var(--black-color);
    position: absolute;
    top: 0px;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s ease;
  }

  .navbar-mobile a {
    position: relative;
    padding: 30px 20px;
    font-size: 1.4rem;
    color: var(--text-color-strong);
    font-weight: 500;
    margin-right: 2.5rem;
  }

  .menu a:hover::before {
    width: 0;
  }

  .menu-icon {
    display: block;
  }
}

</style>