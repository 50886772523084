import Vue from 'vue'
import VueRouter from "vue-router";
import VueScrollTo from 'vue-scrollto';
import Home from "@/pages/home/home-app.vue";
import About from "@/pages/about/about-app.vue";
import Skills from "@/pages/curriculum/skills-app.vue";
import Projects from "@/pages/projectsmain/project-main.vue";
import Contact from "@/pages/contact/app-contact.vue";

Vue.use(VueScrollTo, {
  duration: 800, 
  easing: 'ease',
});

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/skills', component: Skills },
  { path: '/projects', component: Projects },
  { path: '/contact', component: Contact },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;

