<template>
  <section id="others-projects">
    <h2 v-scroll-reveal.reset>OUTROS PROJETOS</h2>
    <ul class="custom-menu" v-scroll-reveal.reset="{ delay: 25 }">
      <li class="item-menu" @click="change('todos')">Todos</li>
      <li class="item-menu" @click="change('mobile')">Mobile</li>
      <li class="item-menu" @click="change('web')">Web</li>
      <li class="item-menu" @click="change('desktop')">Desktop</li>
      <li class="item-menu" @click="change('back')">Back-end</li>
    </ul>
    <ul class="menu-mobile" v-scroll-reveal.reset="{ delay: 25 }">
      <li class="item-menu" @click="change('todos')">
        <i class="bx bx-border-all"></i>
      </li>
      <li class="item-menu" @click="change('mobile')">
        <i class="bx bx-mobile-alt"></i>
      </li>
      <li class="item-menu" @click="change('web')">
        <i class="bx bxl-chrome"></i>
      </li>
      <li class="item-menu" @click="change('desktop')">
        <i class="bx bx-desktop"></i>
      </li>
      <li class="item-menu" @click="change('back')">
        <i class="bx bx-code-alt"></i>
      </li>
    </ul>
    <hr v-scroll-reveal.reset="{ delay: 25 }" />
    <div class="grid">
      <a
        :href="item.link"
        target="blank"
        class="item"
        v-for="(item, index) in list"
        :key="item.id"
        v-scroll-reveal.reset="{ delay: 25 + 25 * index }"
      >
        <i :class="item.icon"></i>
        <h3>{{ item.name }}</h3>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      listMobile: [
         {
          id: 1,
          name: "APP Java- Calculadora",
          link: "https://github.com/AndreFSRamos/Calculadora",
          icon: "bx bxl-java",
        },
         {
          id: 2,
          name: "APP Flutter - Contador de Pessoas",
          link: "https://github.com/AndreFSRamos/Flutter_AppContadorPessoas",
          icon: "bx bxl-flutter",
        },
        {
          id: 3,
          name: "APP Flutter - Buscador de GIF's",
          link: "https://github.com/AndreFSRamos/Flutter_Buscador_GIFs",
          icon: "bx bxl-flutter",
        },
        {
          id: 4,
          name: "APP Flutter - TO DO List",
          link: "https://github.com/AndreFSRamos/smart_list_2.0",
          icon: "bx bxl-flutter",
        },
        {
          id: 5,
          name: "APP Flutter Conversor de Moedas",
          link: "https://github.com/AndreFSRamos/Flutter_AppConversorMoedas",
          icon: "bx bxl-flutter",
        },
         {
          id: 6,
          name: "APP Flutter - Chat Online",
          link: "https://github.com/AndreFSRamos/Flutter_chat",
          icon: "bx bxl-flutter",
        },
        {
          id: 7,
          name: "APP Flutter - Loja Virtual",
          link: "https://github.com/AndreFSRamos/Flutter_Loja_virtual",
          icon: "bx bxl-flutter",
        }
      ],
      listWeb: [
        {
          id: 8,
          name: "Flutter Web - Portifólio",
          link: "https://github.com/AndreFSRamos/my-portfolio-flutter",
          icon: "bx bxl-flutter",
        }
      ],
      listDesktop: [
        {
          id: 9,
          name: "Flutter - Game Ember Quest",
          link: "https://github.com/AndreFSRamos/game-ember-quest",
          icon: "bx bxl-flutter",
        },
      ],
      listBackend: [
        {
          id: 10,
          name: "Spring Boot - Microservice Auth",
          link: "https://github.com/AndreFSRamos/micro-service-to-authentication",
          icon: "bx bxl-spring-boot",
        },
        {
          id: 11,
          name: "Spring Boot - Microservice Send Email",
          link: "https://github.com/AndreFSRamos/micro-service-send-email",
          icon: "bx bxl-spring-boot",
        },
      ],
    };
  },
  methods: {
    change(label) {
      this.list = [];

      if (label === "todos") {
        this.listMobile.map((item) => {
          this.list.push(item);
        });

        this.listDesktop.map((item) => {
          this.list.push(item);
        });

        this.listWeb.map((item) => {
          this.list.push(item);
        });

        this.listBackend.map((item) => {
          this.list.push(item);
        });
      } else if (label === "web") {
        this.list = this.listWeb;
      } else if (label === "mobile") {
        this.list = this.listMobile;
      } else if (label === "desktop") {
        this.list = this.listDesktop;
       }else if (label === "back") {
        this.list = this.listBackend;
      }
      
       const listAux =  this.list;

      this.list = listAux.sort((a, b) => a.id - b.id).reverse();
    },
  },
  cleanLists() {
    this.list = [];
    this.listDesktop = [];
    this.listMobile = [];
    this.listWeb = [];
    this.listBackend = [];
  },
  beforeMount() {
    this.change("todos");
  },
};
</script>

<style scoped>
section {
  padding-top: 100px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--text-color-strong);
}

h2 {
  padding: 20px 0;
  font-size: 2.6em;
  color: var(--text-color-strong);
}

.custom-menu {
  height: 70px;
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.menu-mobile {
  display: none;
  height: 70px;
  width: 100%;
  padding-top: 20px;
  justify-content: space-around;
}

.item-menu {
  padding-left: 15px;
  font-size: 1.8em;
  cursor: pointer;
  list-style: none;
}

.item-menu i {
  font-size: 30px;
}

.item-menu:hover {
  color: var(--main-color);
}

hr {
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: var(--text-color-strong);
}

.grid {
  min-height: 100px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  gap: 5px;
  padding: 20px 0;
  width: 100%;
  text-align: center;
}

.grid .item {
  padding: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 250px;
  font-size: 16px;
  transition: 0.5s ease;
  color: var(--text-color-strong);
  background-size: cover;
  border: 0.5px solid var(--text-color-strong);
  transition: all 0.1s ease-in-out;
}

.grid .item:hover {
  color: var(--main-color);
  border: 0.5px solid var(--main-color);
  box-shadow: 0 0 15px var(--main-color), 0 0 15px var(--main-color) inset;
  text-shadow: 0 0 15px var(--main-color);
}

.grid .item i {
  font-size: 70px;
}

@media (max-width: 1330px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 450px) {
  .custom-menu {
    display: none;
  }

  .menu-mobile {
    display: flex;
  }
}
</style>