<template>
  <div id="app">
    <HeadLineApp />
    <AboutAppVue/>
    <SkillsAppVue/>
    <ProjectMainVue/>
    <OutherProjectsApp />
    <AppContactVue/>
  </div>
</template>

<script>

import HeadLineApp from "./components/head-line.vue";
import AboutAppVue from '../about/about-app.vue';
import SkillsAppVue from '../curriculum/skills-app.vue';
import ProjectMainVue from '../projectsmain/project-main.vue';
import OutherProjectsApp from "../outhersprojects/others-projects.vue";
import AppContactVue from '../contact/app-contact.vue';

export default {
  name: "home-app",
  components: {
    HeadLineApp,
    AboutAppVue,
    SkillsAppVue,
    ProjectMainVue,
    OutherProjectsApp,
    AppContactVue
  },
};
</script>

<style scoped>
</style>