<template>
  <section class="home" id="home">
    <div class="home-content">
      <img
        class="home-image-mobile"
        src="https://bit.ly/3RNMTDj"
        alt="Imagem André Ramos"
        v-scroll-reveal.reset
      />
      <div class="box-left">
        <p v-scroll-reveal.reset="{ delay: 100, scale: 0.9 }">
          Seja bem vindo, eu sou
        </p>

        <h2 v-scroll-reveal.reset="{ delay: 200, scale: 0.9 }">
          <strong>André Ramos</strong><br><strong class="job">Desenvolvedor Full Stack</strong>
        </h2>

        <p v-scroll-reveal.reset="{ delay: 300, scale: 0.9 }">
          Como profissional, meu foco é criar soluções escaláveis e de alta
          performance, criando uma experiência significativa e satisfatória para
          os usuários.
        </p>

        <div class="content-bottom" v-scroll-reveal.reset="{ delay: 400 }">
          <a  href="https://drive.google.com/file/d/1H8Xfn8IsfekW6yiNsUFQKQsrF2RX5W2b/view?usp=sharing" target="blank">
            <btn-neon>Download CV</btn-neon>
          </a>
          <div style="width: 15px" class="space"></div>
          <a v-scroll-to="'#contact'"><btn-neon>CONTATO</btn-neon></a>
        </div>
      </div>
    </div>

    <img
      class="home-image"
      src="https://bit.ly/3RNMTDj"
      alt="Imagem André Ramos"
      v-scroll-reveal.reset="{ delay: 500 }"
    />
  </section>
</template>

<script>
export default {
  name: "HeadLineApp",
};
</script>

<style scoped>
.home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  height: 100vh;
}

h2 {
  padding: 0rem 0 3rem 0;
  font-size: 4.0em;
  font-weight: 700;
  line-height: 1.1;
  color: var(--main-color);
}

.job {
  font-size: .8em;
  color: var(--text-color-strong);
}

.home-content h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-color-strong);
}

.home-content p {
  padding-bottom: 20px;
  z-index: 1;
  font-size: 1.5em;
  font-weight: 400;
  text-align: justify;
  color: var(--text-color-strong);
}

.content-bottom {
  padding: 2.5rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.home-image {
  height: 100%;
  position: relative;
  pointer-events: none;
  object-fit: contain;
}

.home-image-mobile {
  padding-top: 50px;
  display: none;
  height: 300px;
  pointer-events: none;
  object-fit: contain;
}

@media (max-width: 980px) {
  .box-left {
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-image-mobile {
    margin: 0 auto;
  }

  h2 {
    text-align: center;
  }
  .home-image {
    display: none;
  }

  .home-image-mobile {
    display: block;
  }

  .home-content h2 {
    font-size: 3em;
  }

  .content-bottom {
    justify-content: center;
  }
}

@media (max-width: 493px) {
  .content-bottom {
    padding: 2.5rem 7.5px;
    row-gap: 20px;
  }

  .space {
    display: none;
  }
}
</style>