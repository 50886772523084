<template>
  <footer id="footer-app">
    <article class="copy">
      <h3>Copyright© 2023 André Ramos. Todos os direitos reservados.</h3>
    </article>
  </footer>
</template>

<script>
export default {
  name: "Footer-app",
};
</script>

<style scoped>
#footer-app {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  flex-wrap: wrap;
  background: var(--main-color);
}

.copy {
  position: absolute;
  bottom: 0;
  padding: 5px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 80px;
  height: auto;
  font-size: 1em;
  color: var(--white-color);
  font-weight: 500;
  background: var(--main-color);
}

@media (max-width: 750px) {
  #footer-app {
    flex-direction: column;
    align-items: center;
  }
}
</style>