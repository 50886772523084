<template>
  <section id="about">
    <div class="header" id="">
      <h2 v-scroll-reveal.reset>Sobre mim <span>;)</span></h2>
    </div>

    <div id="about-me">
      <div class="box-left marge"></div>
      <div class="box-center">
        <div class="div" v-scroll-reveal.reset="{delay: 100}">
          <p>
           Olá, eu sou André Ramos, tenho 29 anos e sou Desenvolvedor Full Stack. Experiente em projetos ponta a ponta utilizando Vue.js, Java/Kotlin e Flutter, além de arquitetura de microsserviços com Spring Boot. Adepto da prática de testes unitários e de integração e do uso do Docker.
          </p>
          <br />
          <p>
           Pelas equipes por onde passei, fui reconhecido pela capacidade de análise crítica e criatividade. Sou uma pessoa autodidata, colaborativa e que sempre está disposta a ajudar ou a aprender.
          </p>
          <br />
          <br />
          <br />
        </div>
        <div class="content-bottom" v-scroll-reveal.reset="{delay: 200}">
          <a  href="https://drive.google.com/file/d/1H8Xfn8IsfekW6yiNsUFQKQsrF2RX5W2b/view?usp=sharing" target="blank">
            <btn-neon>Download CV</btn-neon>
          </a>
        </div>
      </div>

      <div class="box-right marge"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About-app",
  methods: {
    calcularIdade() {
      const dataNascimento = new Date("1994-09-13");
      const dataAtual = new Date();
      const diferencaEmMilissegundos = dataAtual - dataNascimento;
      const milissegundosEmAnos = 1000 * 60 * 60 * 24 * 365.25;
      const idade = Math.floor(diferencaEmMilissegundos / milissegundosEmAnos);
      return idade;
    },
  },
};
</script>

<style scoped>

.header {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h2 {
  padding: 20px 0;
  font-size: 2.9em;
  color: var(--text-color-strong);
}

#about-me {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-center {
  padding: 50px 0;
}

.marge {
  width: 30%;
}

h3 {
  color: var(--text-color-strong);
  font-size: 2.5em;
  padding-bottom: 20px;
}

p {
  color: var(--text-color);
  font-size: 1.5em;
}

strong {
  color: var(--text-color-strong);
}

span {
  color: var(--main-color);
  font-size: 1.5em;
}

.content-bottom {
  padding: 25px 0 0px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
}
</style>        