<template>
  <section class="project">
    <h3>Founding</h3>
    <p>
      Flouding é uma plataforma web que oferece um ambiente para gestão de
      equipes. Atuei nesse projeto como programador Java/Kotlin freelancer, na
      correção de Bug's em seus serviços de pagamento e envio de notificações.
    </p>
    <div class="content-block" v-if="block">
      <strong>
        Peço desculpas, porém, devido a motivos legais, não é permitido o compartilhamento de informações desse projeto.
      </strong>
    </div>

    <a href="https://app.founding.com.br/login" target="blank">
      <btn-neon>Visitar site</btn-neon>
    </a>
  </section>
</template>

<script>
export default {
  name: "ProjectSecond",
  data() {
    return {
      block: true,
    };
  },
};
</script>

<style scoped>
.project {
  margin: 50px auto;
}

h3 {
  font-size: 2em;
  padding: 0 0 30px 0;
  color: var(--text-color-strong);
  text-align: center;
}

p {
  font-size: 1.5em;
  padding: 10px 0 40px 0;
  color: var(--text-color);
}

.content-block {
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--text-color-strong);
  padding-bottom: 30px;
}

strong {
  font-size: 14px;
}

@media (max-width: 700px) {
  h3 {
    padding: 40px 0 40px 0;
  }

  p {
    text-align: justify;
  }

  .item {
    width: 100%;
  }
}
</style>