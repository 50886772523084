import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Notifications from 'vue-notification/dist/ssr.js'
import VueScrollReveal from '@what-a-faka/vue-scroll-reveal'
import ButtonNeon from './components/button-neon/button-default-neon.vue'

Vue.use(Notifications)
Vue.component('btn-neon', ButtonNeon)

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', 
  duration: 2000,
  scale: 0.9,
  distance: '90px',
  mobile: true
});

Vue.directive('scroll-animate', {
  bind(el, binding) {
    const { target, animationClass, delay } = binding.value;
    const targetElement = el.querySelector(target);
    const targetPosition = targetElement.offsetTop;
    const windowHeight = window.innerHeight;

    const checkVisibility = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition + windowHeight > targetPosition) {
        targetElement.classList.add(animationClass);
      }
    };

    window.addEventListener('scroll', checkVisibility);

    setTimeout(checkVisibility, delay || 0);
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
