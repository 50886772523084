<template>
  <section class="project">
    <h3>Portal do Selo - Tribunal de Justiça do Estado de Roraima</h3>
    <p>
      Projeto em que atuo como programador full stack junto a uma equipe de desenvolvimento, esse projeto se trata de uma aplicação web responsável pelo fornecimento e gestão de selos digitais para o setor notarial no estado de Roraima.
    </p>

    <div class="content-block" v-if="block">
      <strong>
        Peço desculpas, porém, devido a motivos legais, não é permitido o compartilhamento de informações desse projeto.
      </strong>
    </div>

    <a href="https://www.selororaima.com.br/login/mainLogin" target="blank">
      <btn-neon>Visitar site</btn-neon>
    </a>
  </section>
</template>

<script>
export default {
  name: "ProjectPrimary",
   data() {
    return {
      block: true,
    };
  },
};
</script>

<style scoped>
.project {
  margin: 50px auto;
}

h3 {
  font-size: 2em;
  padding: 0 0 30px 0;
  color: var(--text-color-strong);
  text-align: center;
}

p {
  font-size: 1.5em;
  padding: 10px 0 40px 0;
  color: var(--text-color);
}

.content-block {
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--text-color-strong);
  padding-bottom: 30px;
}

strong {
  font-size: 14px;
}

@media (max-width: 700px) {
  h3 {
    padding: 40px 0 40px 0;
  }

  p {
    text-align: justify;
  }

  .item {
    width: 100%;
  }
}
</style>