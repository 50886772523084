<template>
  <section id="contact">
    <notifications group="success">
      <template slot="body" slot-scope="props">
        <div class="success">
          <div>
            <a class="title">
              {{ props.item.title }}
            </a>
            <div v-html="props.item.text"></div>
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-fw fa-close"></i>
          </a>
        </div>
      </template>
    </notifications>
    <notifications group="error">
      <template slot="body" slot-scope="props">
        <div class="error">
          <div>
            <a class="title">
              {{ props.item.title }}
            </a>
            <div v-html="props.item.text"></div>
          </div>
          <a class="close" @click="props.close">
            <i class="fa fa-fw fa-close"></i>
          </a>
        </div>
      </template>
    </notifications>
    <div class="header">
      <h2 v-scroll-reveal.reset>CONTATE ME</h2>
    </div>
    <div id="form">
      <div class="box-center">
        <p v-scroll-reveal.reset="{ delay: 150 }">
          Vamos pater um papo?, Podemos marcar uma video chamada se preferir.
        </p>

        <div class="input" v-scroll-reveal.reset="{ delay: 200 }">
          <input type="text" placeholder="Nome" />
        </div>

        <div class="input" v-scroll-reveal.reset="{ delay: 250 }">
          <input type="text" placeholder="E-mail" />
        </div>

        <div class="input" v-scroll-reveal.reset="{ delay: 300 }">
          <input type="text" placeholder="Assunto" />
        </div>

        <div class="input" v-scroll-reveal.reset="{ delay: 350 }">
          <textarea type="text" placeholder="Mensagem" />
        </div>

        <div class="content-bottom" v-scroll-reveal.reset="{ delay: 400 }">
          <div class="verify-test">
            <label>{{ numberOne }} + </label>
            <label>{{ numberTwo }} = </label>
            <input
              type="number"
              maxlength="3"
              v-model="result"
              @input="verifyTeste"
            />
          </div>
          <btn-neon
            @click="sendEmail"
            :class="{ disable: !isBlock }"
            v-scroll-reveal.reset="{ delay: 450 }"
            >ENVIAR</btn-neon
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "My-services",
  data() {
    return {
      nome: "",
      email: "",
      assunto: "",
      mensagem: "",
      numberOne: this.gerarNumeroAleatorio(),
      numberTwo: this.gerarNumeroAleatorio(),
      result: 0,
      isBlock: false,
    };
  },
  methods: {
    async sendEmail() {
      try {
        this.isBlock = false;
        this.numberOne = this.gerarNumeroAleatorio();
        this.numberTwo = this.gerarNumeroAleatorio();
        this.isBlock = false;
        this.$notify({
          group: "success",
          title: "Seu email foi enviado!",
          text: "Assim que possivel, entrarei em cotato.",
        });
      } catch (e) {
        this.isBlock = false;
        this.$notify({
          group: "error",
          title: "Falha ao enviar o email!",
          text: e.mensage,
        });
      }
    },

    gerarNumeroAleatorio() {
      const min = 1;
      const max = 50;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    verifyTeste() {
      const SUM = this.numberOne + this.numberTwo;
      if (this.result === SUM.toString() ? true : false) {
        this.isBlock = true;
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}

h2 {
  padding-top: 50px;
  font-size: 2.6em;
  color: var(--text-color-strong);
}

.header h3 {
  font-size: 1.5em;
  font-weight: 500;
  color: var(--text-color-strong);
}

#form {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;
}

.alert {
  background-color: #42a85f;
}

.success {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: var(--white-color);
  font-size: 1.2rem;
  background: #68cd86;
  border-radius: 4px;
  border: 0.2rem solid #42a85f;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px;
  color: var(--white-color);
  font-size: 1.2rem;
  background: #e54d42;
  border-radius: 4px;
  border: 0.2rem solid #b82e24;
  box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.success .title {
  font-size: 1.2rem;
  font-weight: bold;
}

.success i {
  font-size: 2rem;
}

p {
  color: var(--text-color-strong);
  font-size: 1.6em;
  font-weight: 400;
  padding: 0 0 20px 0;
}

.input {
  padding: 10px 0;
}

.input input,
textarea {
  color: var(--text-color-strong);
  font-size: 1.5em;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  padding-left: 10px;
  background: var(--black-color);
  border: 0.5px solid var(--main-color);
}

.input input:hover,
textarea:hover {
  box-shadow: 0 0 15px var(--main-color), 0 0 10px var(--main-color) inset;
}

textarea {
  padding-top: 10px;
  min-height: 100px;
  min-width: 100%;
}

.content-bottom {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  color: var(--text-color-strong);
  border: 2px solid var(--text-color-strong);
}

.verify-test label {
  font-size: 1.6em;
  color: var(--text-color);
  font-weight: 600;
}

.verify-test input {
  font-size: 1.4em;
  color: var(--text-color);
  font-weight: 600;
  background: var(--white-color);
  width: 70px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
}
</style>